import React, { useEffect } from "react";
import line from "../images/line4-new.svg";
import quotes from "../images/quotes.svg";
import choco from "../images/fourth_chocolate.png";
import bar1 from "../images/fourth_bar1.png";
import bar2 from "../images/fourth_bar2.png";
import bar3 from "../images/fourth_bar3.png";
import bar4 from "../images/fourth_bar4.png";
import tetris1 from "../images/bitbybit_2.svg";
import tetris2 from "../images/bitbybit_3.svg";
import tetris3 from "../images/bitbybit_4.svg";
import tetris4 from "../images/bitbybit_5.svg";
import tetris5 from "../images/bitbybit_6.svg";
import tetris6 from "../images/bitbybit_7.svg";
import tetris7 from "../images/bitbybit_8.svg";
import tetris8 from "../images/bitbybit_9.svg";
import tetris9 from "../images/bitbybit_10.svg";
import tetris10 from "../images/bitbybit_11.svg";
import tetris11 from "../images/bitbybit_12.svg";
import tetris12 from "../images/bitbybit_13.svg";
import tetris13 from "../images/bitbybit_14.svg";
import tetris14 from "../images/bitbybit_15.svg";
import tetris15 from "../images/bitbybit_16.svg";
import { gsap, Power4 } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
export default function Fourth() {
   gsap.registerPlugin(ScrollTrigger);
   useEffect(() => {
      const tl1 = gsap.timeline({
         scrollTrigger: {
            trigger: ".fourth",
            start: () => "+=" + window.innerWidth * 2.6,
         },
      });

      tl1.from(".fourth__small-text1", 1, { x: "-150%", y: "100", ease: Power4 }, "-=0.5")
         .from(".fourth__small-text2", 1, { x: "-150%", y: "100", ease: Power4 }, "-=0.5")
         .from(".fourth__small-text3", 1, { x: "-150%", y: "100", ease: Power4 }, "-=0.5")
         .from(".fourth__small-text4", 1, { x: "-150%", y: "100", ease: Power4 }, "-=0.5");

      const tl2 = gsap.timeline({
         scrollTrigger: {
            trigger: ".fourth",
            start: () => "+=" + window.innerWidth * 2.8,
         },
      });

      tl2.from(".fourth__bar", 2, { width: 0, ease: Power4 }, "-=1");

      const tl3 = gsap.timeline({
         scrollTrigger: {
            trigger: ".fourth",
            start: () => "+=" + window.innerWidth * 2.8,
         },
      });

      tl3.from(".fourth__big-text1", 1, { y: 200, ease: Power4 }, "-=0.5")
         .from(".fourth__big-text2", 1, { y: 200, ease: Power4 }, "-=0.5")
         .from(".fourth__big-text3", 1, { y: 200, ease: Power4 }, "-=0.5");

      const tl4 = gsap.timeline({
         scrollTrigger: {
            trigger: ".fourth",
            start: () => "+=" + window.innerWidth * 2.9,
         },
      });

      tl4.from(".tetris11", 1.5, { y: -2000, ease: Power4 }, "-=1.3")
         .from(".tetris15", 1.5, { y: -2000, ease: Power4 }, "-=1.3")

         .from(".tetris1", 1.5, { y: -2000, ease: Power4 }, "-=1.3")
         .from(".tetris7", 1.5, { y: -2000, ease: Power4 }, "-=1.3")

         .from(".tetris2", 1.5, { y: -2000, ease: Power4 }, "-=1.3")
         .from(".tetris12", 1.5, { y: -2000, ease: Power4 }, "-=1.3")
         .from(".tetris10", 1.5, { y: -2000, ease: Power4 }, "-=1.3")
         .from(".tetris9", 1.5, { y: -2000, ease: Power4 }, "-=1.3")
         .from(".tetris13", 1.5, { y: -2000, ease: Power4 }, "-=1.3")
         .from(".tetris4", 1.5, { y: -2000, ease: Power4 }, "-=1.3")
         .from(".tetris6", 1.5, { y: -2000, ease: Power4 }, "-=1.3")

         .from(".tetris3", 1.5, { y: -2000, ease: Power4 }, "-=1.3")
         .from(".tetris14", 1.5, { y: -2000, ease: Power4 }, "-=1.3")
         .from(".tetris8", 1.5, { y: -2000, ease: Power4 }, "-=1.3")

         .from(".tetris5", 1.5, { y: -2000, ease: Power4 }, "-=1.3")
         .from(".tetris16", 1.5, { y: -2000, ease: Power4 }, "-=1.3");
   }, []);
   return (
      <div className="fourth slide relative" id="fourth">
         <div className="quotes-wrapper absolute">
            <img className="quotes " src={quotes} />
         </div>
         <img className="absolute line" src={line} />

         <div className="tetris absolute">
            <div className="relative w-full h-full">
               <img className="tetris1 absolute" src={tetris1} alt="" />
               <img className="tetris2 absolute" src={tetris2} alt="" />
               <img className="tetris3 absolute" src={tetris3} alt="" />
               <img className="tetris4 absolute" src={tetris4} alt="" />
               <img className="tetris5 absolute" src={tetris5} alt="" />
               <img className="tetris6 absolute" src={tetris6} alt="" />
               <img className="tetris7 absolute" src={tetris7} alt="" />
               <img className="tetris8 absolute" src={tetris8} alt="" />
               <img className="tetris9 absolute" src={tetris9} alt="" />
               <img className="tetris10 absolute" src={tetris10} alt="" />
               <img className="tetris11 absolute" src={tetris11} alt="" />
               <img className="tetris12 absolute" src={tetris12} alt="" />
               <img className="tetris13 absolute" src={tetris13} alt="" />
               <img className="tetris14 absolute" src={tetris14} alt="" />
               <img className="tetris15 absolute" src={tetris15} alt="" />
            </div>
         </div>

         {/* <div className="absolute choco-wrapper">
            <div className="relative w-full h-full">
               <img className="el5 absolute" src={bar4} alt="" />
               <img className="el4 absolute" src={bar3} alt="" />
               <img className="el3 absolute" src={bar2} alt="" />
               <img className="el2 absolute" src={bar1} alt="" />
               <img className="el1 absolute" src={choco} alt="" />
            </div>
         </div> */}

         <div className="big-text-wrapper absolute flex flex-col items-start justify-center">
            <div>
               <p className="fourth__big-text1">Enjoy creativity</p>
            </div>
            <div>
               <p className="fourth__big-text2">
                  together <span>BIT BY BIT,</span>
               </p>
            </div>
            <div>
               <p className="fourth__big-text3">we are talented</p>
            </div>
            <div>
               <p className="fourth__big-text4">
                  <span>we feel THE BEAT</span>
               </p>
            </div>
         </div>
         <div className="small-text-wrapper absolute flex flex-col items-end justify-center">
            <div>
               <p className="fourth__small-text1">Jedno wiemy na pewno:</p>
            </div>
            <div>
               <p className="fourth__small-text2">na początku potrzebny</p>
            </div>
            <div>
               <p className="fourth__small-text3">jest pomysł, i kto</p>
            </div>
            <div>
               <p className="fourth__small-text4">musi go mieć!</p>
            </div>
         </div>

         <div className="line-block fourth__bar absolute"></div>
      </div>
   );
}
