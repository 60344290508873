import axios from "axios";

export const postFormData = (formData) => {
   console.log(formData);
   return axios({
      method: "post",
      url: "/logic/send_email.php",
      data: formData,
      headers: {
         "Content-Type": "application/x-www-form-urlencoded",
      },
   });
};
