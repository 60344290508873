import React, { useEffect } from "react";
import main from "../images/seventh.png";
import quotes from "../images/quotes.svg";
import line from "../images/line7-new.svg";
import portfolio from "../images/portfolio.svg";
import choco from "../images/choco_portfolio.svg";

import { gsap, Power4 } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
export default function Seventh() {
   gsap.registerPlugin(ScrollTrigger);
   useEffect(() => {
      const tl1 = gsap.timeline({
         scrollTrigger: {
            trigger: ".seventh",
            start: () => "+=" + window.innerWidth * 5.2,
         },
      });

      tl1.from(".seventh__big-text1", 1, { x: "150%", ease: Power4 }, "-=0.5")
         .from(".seventh__big-text2", 1, { x: "-150%", ease: Power4 }, "-=0.5")
         .from(".seventh__big-text3", 1, { x: "150%", ease: Power4 }, "-=0.5")
         .from(".seventh__big-text4", 1, { x: "-150%", ease: Power4 }, "-=0.5");

      const tl2 = gsap.timeline({
         scrollTrigger: {
            trigger: ".seventh",
            start: () => "+=" + window.innerWidth * 5.4,
         },
      });

      tl2.from(".seventh__line2", 2, { width: 0, ease: Power4 }, "-=1").from(".seventh__line1", 2, { width: 0, ease: Power4 }, "-=1.6").from(".seventh__line3", 2, { width: 0, ease: Power4 }, "-=1.6");
   }, []);
   return (
      <div className="seventh slide relative" id="seventh">
         <div className="quotes-wrapper absolute">
            <img className="quotes " src={quotes} />
         </div>
         <img className="absolute line" src={line} />
         <img className="absolute portfolio" src={portfolio} />
         <img className="absolute choco" src={choco} />

         <div className="lines w-full absolute flex flex-col items-center justify-center ">
            <div className="first seventh__line1"></div>
            <div className="second seventh__line2"></div>
            <div className="third seventh__line3"></div>
         </div>

         <div className="text-container flex flex-col items-start justify-center absolute">
            <div>
               <p className="seventh__big-text1">Created</p>
            </div>
            <div>
               <p className="seventh__big-text2">together,</p>
            </div>
            <div>
               <p className="seventh__big-text3">
                  <span>with</span>
               </p>
            </div>
            <div>
               <p className="seventh__big-text4">
                  <span>pleasure</span>
               </p>
            </div>
         </div>
      </div>
   );
}
