import React, { useEffect, useState } from "react";
import line from "../images/line11-new.svg";
import contact_lines from "../images/contact_lines.png";
import send from "../images/send.png";
import the_magic from "../images/the-magic.png";
import { ReactComponent as Zapraszamy } from "../images/zapraszamy.svg";
import { gsap, Power4 } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import * as api from "../api.js";
export default function Eleventh() {
   gsap.registerPlugin(ScrollTrigger);
   const [formData, setFormData] = useState({
      name: "",
      email: "",
      phone: "",
      comments: "",
   });
   const sendData = async (e) => {
      e.preventDefault();
      try {
         const response = await api.postFormData(formData);
         console.log(response);
      } catch (error) {
         console.log(error);
      }
   };
   return (
      <div className="eleventh slide relative" id="eleventh">
         <Zapraszamy className=" absolute zapraszamy" />

         <img className="absolute contact-lines" src={contact_lines} alt="" />
         <div className="lines w-full absolute flex flex-col items-start justify-center ">
            <div className="first ninth__line1"></div>
            <div className="second ninth__line2"></div>
            <div className="third ninth__line3"></div>
         </div>
         <div className="circle absolute"></div>
         <img className="line absolute" src={line} />

         <div className="contact-wrapper absolute">
            <form onSubmit={sendData}>
               <div>
                  <input type="text" id="name" name="name" placeholder="imię i nazwisko" value={formData.name} onChange={(e) => setFormData({ ...formData, name: e.target.value })} />
               </div>
               <div>
                  <input type="text" id="email" name="email" placeholder="e-mail" value={formData.email} onChange={(e) => setFormData({ ...formData, email: e.target.value })} />
               </div>
               <div>
                  <input type="text" id="phone" name="phone" placeholder="telefon " value={formData.phone} onChange={(e) => setFormData({ ...formData, phone: e.target.value })} />
               </div>
               <div>
                  <input type="text" id="comments" name="comments" placeholder="treść wiadomości" value={formData.comments} onChange={(e) => setFormData({ ...formData, comments: e.target.value })} />
               </div>
               <button className="send-btn">
                  <img src={send} alt="send" />
               </button>
            </form>
         </div>

         <div className="big-text-wrapper absolute flex flex-col items-start overflow-hidden justify-center">
            <div className="overflow-hidden">
               <p className="tenth__big-text1 overflow-hidden ">
                  Let
                  <div className="inline img-text-wrapper">
                     <img className="inline-block mx-4" src={the_magic} alt="" />
                  </div>
                  begin
               </p>
            </div>
            <div className="overflow-hidden">
               <p className="tenth__big-text2 overflow-hidden ">When the brief gets in</p>
            </div>
            <div className="overflow-hidden">
               <p className="tenth__big-text3 overflow-hidden ">and don’t worry</p>
            </div>
            <div className="overflow-hidden">
               <p className="tenth__big-text4 overflow-hidden ">
                  <span>let’s create our story!</span>
               </p>
            </div>
         </div>

         {/* <div className="big-text-wrapper absolute flex flex-col items-start overflow-hidden justify-center">
            <div className="overflow-hidden">
               <p className="tenth__big-text1 overflow-hidden ">odezwij się,</p>
            </div>
            <div className="overflow-hidden">
               <p className="tenth__big-text2 overflow-hidden ">
                  <span>poszukamy </span>
               </p>
            </div>
            <div className="overflow-hidden">
               <p className="tenth__big-text3 overflow-hidden ">
                  <span>rozwiązań</span>
               </p>
            </div>
         </div> */}
      </div>
   );
}
