import React, { useEffect } from "react";
import thebar from "../images/the_bar.png";
import the from "../images/the.png";
import bar from "../images/bar.png";
import underline from "../images/underline.png";
import line from "../images/line1.svg";
import brandarea from "../images/brandarea.png";
import { gsap, Power4 } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
export default function First() {
   gsap.registerPlugin(ScrollTrigger);
   useEffect(() => {
      const tl1 = gsap.timeline({
         scrollTrigger: {
            trigger: ".first",
            start: "top 100%",
            end: "bottom bottom",
         },
      });

      const tl2 = gsap.timeline({
         scrollTrigger: {
            trigger: ".first",
            start: "top 100%",
            end: "bottom bottom",
         },
      });

      tl2.from(".first-line", 2, { width: 0, ease: Power4 }, "-=1").from(".second-line", 2, { width: 0, ease: Power4 }, "-=1").from(".third-line", 2, { width: 0, ease: Power4 }, "-=1");

      tl1.from(".first__underline", 1, { width: 0, ease: Power4 })
         .from(".the-bar", 2, { y: 250, ease: Power4 })
         .from(".first__text1", 0.5, { x: "-150%", y: "100", ease: Power4 }, "-=0.25")
         .from(".first__text2", 0.5, { x: "-150%", y: "100", ease: Power4 }, "-=0.25")
         .from(".first__text3", 0.5, { x: "-150%", y: "100", ease: Power4 }, "-=0.25");
   }, []);

   return (
      <div className="first slide relative" id="first">
         <img className="line absolute" src={line} alt="" />

         <div className="absolute-center flex flex-col items-center justify-center">
            <div className="flex the-bar-container  overflow-hidden flex-col  items-center justify-center">
               <div className="flex pb-5 the-bar flex-row  justify-between items-center">
                  <div className="img-wrapper overflow-hidden">
                     <img className="first__image" src={brandarea} alt="brandstories" />
                  </div>
                  {/* <div className="img-wrapper overflow-hidden">
                     <img className="first__image" src={the} alt="" />
                  </div>
                  <div className="img-wrapper overflow-hidden">
                     <img className="first__image" src={bar} alt="" />
                  </div> */}
               </div>
               {/* <img className="first__underline" src={underline} alt="" /> */}
            </div>
            <div className="flex flex-col items-center justify-center lines-wrapper">
               <div className="first-line"></div>
               <div className="second-line"></div>
               <div className="third-line"></div>
            </div>

            <div className="w-full pt-8 text-container flex flex-row justify-between">
               <div className="flex flex-col items-start justify-center overflow-hidden">
                  <p className="first__text1">
                     <span>enjoy</span>
                  </p>
                  <p className="first__text2">creativity</p>
                  <p className="first__text3">together</p>
               </div>
               <div className="items-end first-link">
                  <a href="">sprawdź nas ></a>
               </div>
            </div>
         </div>
      </div>
   );
}
