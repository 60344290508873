import React, { useEffect } from "react";
import line from "../images/line6.svg";
import quotes from "../images/quotes.svg";
import { gsap, Power4 } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
export default function Sixth() {
   gsap.registerPlugin(ScrollTrigger);
   useEffect(() => {
      const tl1 = gsap.timeline({
         scrollTrigger: {
            trigger: ".sixth",
            start: () => "+=" + window.innerWidth * 3.8,
         },
      });

      tl1.from(".sixth__big-text1", 1, { x: "-150%", y: "100", ease: Power4 }, "-=0.5").from(".sixth__big-text2", 1, { x: "-150%", y: "100", ease: Power4 }, "-=0.5");
   }, []);
   return (
      <div className="sixth slide relative " id="sixth">
         <div className="relative flex ">
            <img className="absolute line" src={line} alt="" />
            <div className="sixth__left relative">
               <div className="lines w-full absolute flex flex-col items-end justify-center ">
                  <div className="first"></div>
                  <div className="second"></div>
                  <div className="third"></div>
               </div>
            </div>
            <div className="sixth__divider"></div>
            <div className="sixth__right w-full relative">
               <div className="quotes-wrapper absolute">
                  <img className="quotes " src={quotes} />
               </div>
               <div className="content-container flex items-center justify-center w-full absolute">
                  <div className="flex items-center justify-evenly gap-1">
                     <p>design </p>
                     <span>/ </span>
                     <p>branding </p>
                     <span>/ </span>
                     <p>strategia komunikacji </p>
                     <span>/ </span>
                     <p>digital </p>
                     <span>/ </span>
                     <p>social media </p>
                     <span>/ </span>
                     <p>video </p>
                     <span>/ </span>
                  </div>
               </div>
               <div className="big-text-wrapper absolute flex flex-col items-start justify-center">
                  <div>
                     <p className="sixth__big-text1">
                        lets check our <span>skills</span>
                     </p>
                  </div>
                  <div>
                     <p className="sixth__big-text2">
                        and find out what people <span>feels</span>
                     </p>
                  </div>
               </div>
            </div>
         </div>
      </div>
   );
}
