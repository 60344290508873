import React, { useState, useEffect } from "react";
import HorizontalScroll from "react-scroll-horizontal";
import First from "./First";
import Second from "./Second";
import Third from "./Third";
import Fourth from "./Fourth";
import Fifth from "./Fifth";
import Sixth from "./Sixth";
import Seventh from "./Seventh";
import Eighth from "./Eighth";
import Ninth from "./Ninth";
import Tenth from "./Tenth";
import Eleventh from "./Eleventh";

import { gsap, Power4 } from "gsap";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";
import { ScrollTrigger } from "gsap/ScrollTrigger";
export default function Fullpage({ menuActive, setMenuActive }) {
   gsap.registerPlugin(ScrollToPlugin, ScrollTrigger);

   useEffect(() => {
      /* Main navigation */
   }, []);
   return (
      <section id="panels">
         <div className="parent flex flex-nowrap" id="panels-container">
            <First />
            <Second />
            <Third />
            <Fourth />
            {/* <Fifth /> */}
            <Sixth />
            <Seventh />
            {/* <Eighth /> */}
            <Ninth />
            {/* <Tenth /> */}
            <Eleventh />
         </div>
      </section>
   );
}
