import React, { useEffect } from "react";
import quotes from "../images/quotes.svg";
import line from "../images/line9-new.svg";
// import logo1 from "../images/ninth_logo1.png";
import logo2 from "../images/ninth_logo2.png";
import logo3 from "../images/ninth_logo3.png";
import logo4 from "../images/ninth_logo4.png";
// import logo5 from "../images/ninth_logo5.png";
import logo6 from "../images/ninth_logo6.png";
import logo7 from "../images/ninth_logo7.png";
import logo8 from "../images/ninth_logo8.png";
import logo9 from "../images/ninth_logo9.svg";
import logo10 from "../images/ninth_logo10.svg";
// import logo9 from "../images/ninth_logo7.png";
// import logo10 from "../images/ninth_logo8.png";

import { gsap, Power4 } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
export default function Ninth() {
   useEffect(() => {
      const tl2 = gsap.timeline({
         scrollTrigger: {
            trigger: ".ninth",
            start: () => "+=" + window.innerWidth * 6.2,
         },
      });

      tl2.from(".ninth__line2", 2, { width: 0, ease: Power4 }, "-=1").from(".ninth__line1", 2, { width: 0, ease: Power4 }, "-=1.6").from(".ninth__line3", 2, { width: 0, ease: Power4 }, "-=1.6");

      const tl3 = gsap.timeline({
         scrollTrigger: {
            trigger: ".ninth",
            start: () => "+=" + window.innerWidth * 6.2,
         },
      });

      tl3.from(".ninth__big-text1", 1, { y: 200, ease: Power4 }, "-=0.5")
         .from(".ninth__big-text2", 1, { y: 200, ease: Power4 }, "-=0.5")
         .from(".ninth__big-text3", 1, { y: 200, ease: Power4 }, "-=0.5");
   }, []);
   return (
      <div className="ninth slide" id="ninth">
         <div className=" relative flex ">
            <img className="ninth_main absolute top-0" src={line} alt="" />
            <div className="ninth__left relative flex h-full flex-col items-center justify-center">
               <div className="absolute side-text">
                  <p>Brands that trusted our team:</p>
               </div>
            </div>
            <div className="ninth__right w-full h-full relative">
               <div className="quotes-wrapper absolute">
                  <img className="quotes " src={quotes} />
               </div>
               <div className="lines w-full absolute flex flex-col items-end justify-center ">
                  <div className="first ninth__line1"></div>
                  <div className="second ninth__line2"></div>
                  <div className="third ninth__line3"></div>
               </div>
               <div className="big_text absolute">
                  <div>
                     <h1 className="ninth__big-text1">Everybody loves someone</h1>
                  </div>
                  <div>
                     <h1 className="ninth__big-text2">We love stories</h1>
                  </div>
                  <div>
                     <h1 className="ninth__big-text3">
                        <span>Come and join us!</span>
                     </h1>
                  </div>
               </div>
               <div className="logos px-16 absolute flex  flex-row flex-wrap table-captionitems-center justify-center gap-12">
                  {/* <div>
                     <img src={logo1} alt="" />
                  </div> */}
                  <div>
                     <img src={logo2} alt="" />
                  </div>
                  <div>
                     <img src={logo3} alt="" />
                  </div>
                  <div>
                     <img src={logo4} alt="" />
                  </div>
                  {/* <div>
                     <img src={logo5} alt="" />
                  </div> */}
                  {/* <div>
                     <img src={logo6} alt="" />
                  </div> */}
                  <div>
                     <img src={logo7} alt="" />
                  </div>
                  <div>
                     <img src={logo8} alt="" />
                  </div>
                  {/* <div>
                     <img src={logo3} alt="" />
                  </div>
                  <div>
                     <img src={logo4} alt="" />
                  </div> */}
                  <div>
                     <img src={logo9} alt="" />
                  </div>
                  <div>
                     <img src={logo10} alt="" />
                  </div>
               </div>
            </div>
         </div>
      </div>
   );
}
