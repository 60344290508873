import React, { useEffect } from "react";
import line from "../images/line3.svg";
import quotes from "../images/quotes.svg";
import legs from "../images/legs.png";
import { gsap, Power4 } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
export default function Third() {
   gsap.registerPlugin(ScrollTrigger);
   useEffect(() => {
      const tl1 = gsap.timeline({
         scrollTrigger: {
            trigger: ".third",
            start: () => "+=" + window.innerWidth * 1.3,
         },
      });

      tl1.from(".third__small-text1", 1, { x: "-150%", y: "100", ease: Power4 }, "-=0.5")
         .from(".third__small-text2", 1, { x: "-150%", y: "100", ease: Power4 }, "-=0.5")
         .from(".third__small-text3", 1, { x: "-150%", y: "100", ease: Power4 }, "-=0.5")
         .from(".third__small-text4", 1, { x: "-150%", y: "100", ease: Power4 }, "-=0.5");

      const tl2 = gsap.timeline({
         scrollTrigger: {
            trigger: ".third",
            start: () => "+=" + window.innerWidth * 1.8,
         },
      });

      tl2.from(".third__bar", 2, { width: 0, ease: Power4 }, "-=1");

      const tl3 = gsap.timeline({
         scrollTrigger: {
            trigger: ".third",
            start: () => "+=" + window.innerWidth * 1.8,
         },
      });

      tl3.from(".third__big-text1", 1, { y: 200, ease: Power4 }, "-=0.5")
         .from(".third__big-text2", 1, { y: 200, ease: Power4 }, "-=0.5")
         .from(".third__big-text3", 1, { y: 200, ease: Power4 }, "-=0.5");
   }, []);
   return (
      <div className="third slide relative" id="third">
         <img src={legs} className="third-legs" alt="" />
         <div className="bar third__bar"></div>
         <div className="quotes-wrapper absolute">
            <img className="quotes " src={quotes} />
         </div>
         <img className="absolute line" src={line} />

         <div className="line"></div>
         <div className="big-text-wrapper third-big-text-wrapper absolute flex flex-col items-start justify-center">
            <div className="overflow-hidden">
               <p className="third__big-text1">And as we talk</p>
            </div>

            <div className="overflow-hidden">
               <p className="third__big-text2">
                  <span>The Effects</span> are coming
               </p>
            </div>

            <div className="overflow-hidden">
               <p className="special third__big-text3">
                  Just let them w<span>a</span>
                  <span>l</span>
                  <span>k</span>
               </p>
            </div>
         </div>
         <div className="small-text-wrapper absolute flex flex-col items-start justify-center">
            <div className="overflow-hidden">
               <p className="third__small-text1">Ponieważ tworzymy kampanie</p>
            </div>

            <div className="overflow-hidden">
               <p className="third__small-text2">w ścisłej komunikacji z Klientem</p>
            </div>

            <div className="overflow-hidden">
               <p className="third__small-text3">najlepsze efekty pojawiają się</p>
            </div>

            <div className="overflow-hidden">
               <p className="third__small-text4">wkrótce!</p>
            </div>
         </div>
      </div>
   );
}
